<template>
  <div class="stock-page">
    <KTCodePreview v-bind:title="'Danh sách tồn kho'">
      <template v-slot:toolbar>
        <div class="row">
          <b-dropdown
            size="sm"
            right
          >
            <template slot="button-content">
              <i
                style="font-size: 1rem"
                class="fas fa-cog"
              ></i>
              <span class="font-weight-bolder">Thao tác</span>
            </template>
            <b-dropdown-item @click="exportExcel">
              <span>
                <i
                  style="font-size: 1rem"
                  class="far fa-file-excel"
                ></i>
                &nbsp; Xuất Excel</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
      <template v-slot:preview>
        <b-row class="mb-5">
          <b-col>
            <b-input
              size="sm"
              placeholder="Nhập tên, mã, mã vạch sản phẩm"
              v-model="searchProduct"
              append-icon="search"
              single-line
              hide-details
              class="input-style"
              v-on:keyup.enter="onFilter()"
            ></b-input>
          </b-col>
          <b-col>
            <div>
              <Autosuggest
                :model="selectedCateName"
                :suggestions="filteredCateOptions"
                placeholder="danh mục sản phẩm"
                @select="onSelectedCate"
                @change="onInputCateChange"
                suggestionName="suggestionName"
              />
            </div>
          </b-col>
          <b-col>
            <BDropdownCustom
              v-model="value"
              :options="filterdOptions"
              :placeholder="'cửa hàng'"
              :matchKey="['name']"
              :searchable="true"
            />
          </b-col>
          <b-col>
            <Autosuggest
              :model="selectedCate2Name"
              :suggestions="filteredInternalCateOptions"
              placeholder="danh mục nội bộ"
              @select="onSelectedCate2"
              @change="onInputCate2Change"
              suggestionName="suggestionName"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <Autosuggest
              :model="selectedBrandName"
              :suggestions="filteredBrandOptions"
              placeholder="thương hiệu"
              @select="onSelectedBrand"
              @change="onInputBrandChange"
            />
          </b-col>
          <b-col class="mb-4">
            <div style="display: flex !important">
              <b-form-select
                size="sm"
                v-model="selectedProductType"
                :options="listProductType"
                value-field="id"
                text-field="name"
                class="col-md-8 select-style"
              ></b-form-select>
              <span class="ml-1 mr-1"></span>
              <b-checkbox
                v-model="haveStock"
                class="mt-2"
              >Có tồn</b-checkbox>
            </div>
          </b-col>
          <b-col>
            <b-checkbox
              v-model="byParent"
              class="mt-2"
            >Tồn theo SP cha</b-checkbox>
          </b-col>
          <b-col></b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col cols="1">
            <b-button
              size="sm"
              variant="primary"
              style="fontweight: 600; width: 70px"
              @click="onFilter()"
            >Lọc</b-button>
          </b-col>
          <b-col class="d-flex d-inline justify-content-end">
            <span class="mr-2">
              <i class="fas fa-circle fa-sm text-dark"></i>
              Tồn có thể bán
            </span>
            <span
              class="mr-2"
              v-if="settingTable.quantity.includes(2)"
            >
              <i class="fas fa-circle fa-sm text-primary"></i>
              Đang chuyển kho
            </span>
            <span
              class="mr-2"
              v-if="settingTable.quantity.includes(3)"
            >
              <i class="fas fa-circle fa-sm text-warning"></i>
              Đang giữ hàng
            </span>
            <span
              class="mr-2"
              v-if="settingTable.quantity.includes(4)"
            >
              <i class="fas fa-circle fa-sm text-danger"></i>
              Đang vận chuyển
            </span>
            <span
              class="mr-2"
              v-if="settingTable.quantity.includes(5)"
            >
              <i class="fas fa-circle fa-sm warranty-color"></i>
              Đang bảo hành
            </span>
            <b-dropdown
              no-caret
              size="sm"
              variant="secondary"
              v-b-tooltip.hover
              title="Hiển thị"
              right
            >
              <template v-slot:button-content>
                <i
                  class="flaticon2-settings"
                  style="font-size: 1rem; padding-right: 0px"
                >
                </i>
              </template>

              <b-dropdown-header id="dropdown-header-label">
                <strong>Hiển thị:</strong>
              </b-dropdown-header>

              <div class="dropdown-item bg-white text-body">
                <b-form-checkbox-group
                  v-model="settingTable.quantity"
                  :options="[
                    {
                      id: 1,
                      name: 'Tồn có thể bán',
                      disabled: true,
                    },
                    {
                      id: 2,
                      name: 'Đang chuyển kho',
                    },
                    {
                      id: 3,
                      name: 'Đang giữ hàng',
                    },
                    {
                      id: 4,
                      name: 'Đang vận chuyển',
                    },
                    {
                      id: 5,
                      name: 'Đang bảo hành',
                    },
                  ]"
                  stacked
                  value-field="id"
                  text-field="name"
                ></b-form-checkbox-group>
              </div>
            </b-dropdown>
          </b-col>
        </b-row>

        <div id="scroll-table">
          <b-table
            :items="listData"
            :fields="fields"
            :small="true"
            :busy="onLoading"
            responsive
            bordered
            hover
          >
            <template v-slot:table-busy>
              <vcl-table
                :speed="5"
                :animate="true"
                :columns="20"
              ></vcl-table>
            </template>

            <template v-slot:cell(productName)="row">
              <span
                v-if="row.item.barCode"
                v-text="row.item.barCode"
                style="white-space: normal; font-size: 12px; font-weight: 500"
                class="d-block"
              ></span>
              <b
                v-if="row.item.productCode"
                class="d-block"
                style="font-size: 12px; font-weight: 500; margin-bottom: 0"
              >
                ( {{ row.item.productCode }} )
              </b>
              <b
                v-text="row.item.productName"
                class="d-block"
                style="white-space: normal"
              ></b>
            </template>
            <template v-slot:cell(sellingPrice)="row">
              <div style="text-align: end">
                <span v-text="convertPrice(row.item.sellingPrice)"></span>
              </div>
            </template>
            <template v-slot:cell(totalQuantityInStock)="row">
              <div style="text-align: end">
                <b
                  v-text="row.item.totalQuantityInStock"
                  class="mr-1"
                  v-b-tooltip.hover.bottom
                  title="Tổng tồn có thể bán"
                ></b>
                <b
                  class="text-primary mr-1 font-size-setting"
                  v-if="
                    settingTable.quantity.includes(2) &&
                    row.item.totalQuantityTranfer
                  "
                  v-b-tooltip.hover.bottom
                  title="Tổng đang chuyển kho"
                >
                  {{ '+' + row.item.totalQuantityTranfer }}
                </b>
                <b
                  class="text-warning font-size-setting"
                  v-if="
                    settingTable.quantity.includes(3) &&
                    row.item.totalQuantityHolding
                  "
                  v-b-tooltip.hover.bottom
                  title="Tổng đang giữ hàng"
                >
                  {{ '+' + row.item.totalQuantityHolding }}
                </b>
                <b
                  class="text-danger font-size-setting"
                  v-if="
                    settingTable.quantity.includes(4) &&
                    row.item.totalQuantityDelivery
                  "
                  v-b-tooltip.hover.bottom
                  title="Tổng đang vận chuyển"
                >
                  {{ '+' + row.item.totalQuantityDelivery }}
                </b>
                <b
                  class="warranty-color font-size-setting"
                  v-if="
                    settingTable.quantity.includes(5) &&
                    row.item.totalQuantityWarranty
                  "
                  v-b-tooltip.hover.bottom
                  title="Tổng đang bảo hành"
                >
                  {{ '+' + row.item.totalQuantityWarranty }}
                </b>
              </div>
            </template>
            <template #cell()="data">
              <div v-html="data.value"></div>
            </template>
          </b-table>
        </div>
        <b-row>
          <b-col>
            <p
              class="mt-3 text-dark"
              style="font-weight: 500"
            >
              Tổng số :
              {{ totalItem }}
            </p>
          </b-col>
          <b-col>
            <b-pagination-nav
              class="custom-pagination"
              v-if="numberOfPage >= 2"
              :link-gen="linkGen"
              :number-of-pages="numberOfPage"
              use-router
              @change="fetchData"
              align="right"
              first-class="page-item-first btn btn-icon btn-sm m-1"
              prev-class="page-item-prev btn btn-icon btn-sm m-1"
              next-class="page-item-next btn btn-icon btn-sm m-1 "
              last-class="page-item-last btn btn-icon btn-sm m-1 "
              page-class="btn btn-icon btn-sm border-0 m-1"
            >
              <template v-slot:first-text>
                <span>
                  <i class="ki ki-double-arrow-back icon-xs"></i>
                </span>
              </template>

              <template v-slot:prev-text>
                <i class="ki ki-arrow-back icon-xs"></i>
              </template>

              <template v-slot:next-text>
                <i class="ki ki-arrow-next icon-xs"></i>
              </template>

              <template v-slot:last-text>
                <span class="text-info">
                  <i class="ki ki-double-arrow-next icon-xs"></i>
                </span>
              </template>
            </b-pagination-nav>
          </b-col>
        </b-row>
      </template>
    </KTCodePreview>
    <ProductStockExcel :param=excelParams />
  </div>
</template>

<style scope>
.d-flex {
  display: -webkit-box !important;
}
</style>
<style lang="scss">
.stock-page {
  .input-style {
    border: 1px solid #ced4da;
  }

  .checkbox-item {
    position: absolute;
    right: 1vw;
  }

  .checkbox-label {
    display: block;
  }

  table {
    margin: 0;
    border-collapse: collapse;
    border-spacing: 0;
    table-layout: fixed;
    th {
      position: relative;
      z-index: 1;
    }

    td,
    th {
      vertical-align: middle;
      padding: 0.75rem;
    }

    th:first-child {
      position: sticky !important;
      left: 0;
      z-index: 2;
      background-color: white;
    }
    th:nth-child(2) {
      position: sticky !important;
      left: 250px;
      z-index: 2;
      background-color: white;
    }

    td:first-child {
      position: sticky;
      left: 0;
      z-index: 0;
      background-color: white;
    }

    td:nth-child(2) {
      position: sticky;
      left: 250px;
      z-index: 0;
      background-color: white;
    }

    .font-size-setting {
      font-size: 0.86rem;
    }
  }
  .labelInput {
    font-weight: 600;
  }
  .productCode:hover {
    text-decoration: underline;
  }

  .inputText {
    width: 22%;
  }
  .btn.btn-icon.btn-sm,
  .btn-group-sm > .btn.btn-icon {
    height: calc(1.35em + 1.1rem + 2px);
    width: auto;
  }
  .page-item.disabled {
    cursor: not-allowed;
    &.bv-d-xs-down-none {
      .page-link {
        margin: 0.35rem 0.25rem;
        text-align: center;
      }
    }
  }

  .icon:hover {
    background-color: #90c6fc;
  }

  .selectedProClass {
    width: 4%;
    text-align: center;
  }
  .productNameClass {
    width: 20%;
  }
  .productNameNotPriceClass {
    width: 20%;
  }
  .providerClass {
    width: 12%;
  }
  .storeClass {
    width: 12%;
    align-items: center;
  }
  .importPriceProClass {
    width: 10%;
  }
  .salePriceProClass {
    width: 10%;
  }
  .statusProClass {
    width: 15%;
  }
  .dropdown-header {
    color: #3699ff !important;
  }

  .imeiCode:hover {
    text-decoration: underline;
  }

  .warranty-color {
    color: #575d60 !important;
  }
}
</style>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import localData from '@/utils/saveDataToLocal';
import { VclTable } from 'vue-content-loading';
import { currencyMask, removeAccents, convertPrice } from '@/utils/common';
import decounce from 'debounce';
import { cloneDeep, map, find, assign } from 'lodash';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import BDropdownCustom from '@/view/base/bootstrap/BDropdownCustom.vue';
import { makeToastFaile } from '@/utils/common';

import ProductStockExcel from '../../components/product-stock/ProductStockExcel.vue';
export default {
  data() {
    return {
      excelParams: null,
      selectedProduct: '',
      originalPrice: '',
      sellingPrice: '',
      productName: '',
      productId: '',
      hoverPrice: false,
      parentProductId: '',
      childProductId: '',
      selectedBrand: null,
      listBrand: [],
      currentSort: 'createdAt',
      currentSortDir: 'DESC',
      btnCreate: {
        fontWeight: '600!important',
      },
      selected: [],
      checkAllPro: false,
      fields: [
        {
          key: 'productName',
          label: 'Sản phẩm',
          thStyle: {
            width: '250px',
          },
        },
        {
          key: 'sellingPrice',
          label: 'Giá bán',
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '160px',
          },
        },
        {
          key: 'totalQuantityInStock',
          label: 'Tổng tồn',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '100px',
          },
        },
      ],
      search: '',
      listData: [],
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      page: 1,
      totalItem: 0,
      onLoading: false,
      numberOfPage: 0,
      filteredOptions: [],
      optionsStore: [
        {
          data: [],
        },
      ],
      searchProduct: '',
      listProduct: [],
      showEditPriceModal: false,
      mask: currencyMask,
      listStore: [],
      selectedStore: null,
      searchStock: '',
      listProductType: [
        { id: null, name: 'Tất cả' },
        { id: 1, name: 'Sản phẩm' },
        { id: 2, name: 'Sản phẩm theo IMEI' },
      ],
      selectedProductType: null,
      value: [],
      optionStores: [],
      haveStock: true,
      cateOptions: [
        {
          data: [],
        },
      ],
      filteredCateOptions: [],
      selectedCateId: null,
      selectedCateName: '',
      brandOptions: [
        {
          data: [],
        },
      ],
      filteredBrandOptions: [],
      selectedBrandId: null,
      selectedBrandName: '',
      internalCateOptions: [
        {
          data: [],
        },
      ],
      filteredInternalCateOptions: [],
      selectedCate2Id: null,
      selectedCate2Name: '',
      byParent: false,
      settingTable: {
        quantity: [1, 2, 3, 4, 5],
      },
      filterdOptions: [],
      filterdOptionStores: [],
    };
  },
  components: {
    KTCodePreview,
    VclTable,
    Autosuggest,
    BDropdownCustom,
    ProductStockExcel,
  },
  computed: {},

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Tồn kho', route: '/productStock' },
      { title: 'Danh sách tồn kho' },
    ]);
    this.fetchStoreForOption();
    this.fetchStore();
  },
  created() {
    let imeiCode = this.$route.query.code;
    let searchProduct = this.$route.query.searchProduct;
    let storeId = this.$route.query.storeId;
    if (imeiCode !== undefined) {
      this.search = imeiCode;
    }
    if (searchProduct !== undefined) {
      this.searchProduct = searchProduct;
    }
    if (storeId !== undefined) {
      this.selectedStore = storeId;
    }
    this.fetchCategory();
    this.fetchBrand();
    this.checkViewOriginalPrice();
    this.fetchInternalCategory();
  },
  methods: {
    convertPrice,
    fetchData: function () {
    //   if(this.checkPermission('STOCK_SEARCH_PRODUCT') && !this.searchProduct.trim()){  
    //     return makeToastFaile('Vui lòng nhập sản phẩm để tìm kiếm!')
    // }
      this.onLoading = true;
      this.page = this.$route.query.page || 1;

      let listId = [];
      if (this.value.length) {
        listId = this.value.map(({ code }) => code);
      }
      this.filterdOptions = cloneDeep(this.optionStores);
      if (this.selectedCateName.trim() === '') {
        this.selectedCateId = null;
      }
      if (this.selectedBrandName.trim() === '') {
        this.selectedBrandId = null;
      }
      if (this.selectedCate2Name.trim() === '') {
        this.selectedCate2Id = null;
      }
      const params = {
        page: this.page,
        limit: 10,
        searchProduct: this.searchProduct,
        searchStore: listId,
        productType: this.selectedProductType,
        checkStock: this.haveStock,
        cateId: this.selectedCateId,
        brandId: this.selectedBrandId,
        internalCateId: this.selectedCate2Id,
        byParent: this.byParent,
      };

      ApiService.query('productStock/getAll', {
        params,
      }).then(({ data }) => {
        this.listData = data.data.data;
        this.numberOfPage = data.data.total_page;
        this.totalItem = data.data.total_row;
        this.onLoading = false;
      }).catch((error) => {
        this.onLoading = false;
        if (error.response) {
          makeToastFaile(
            error.response.data ? error.response.data.message : 'Lỗi',
          );
        } else {
          makeToastFaile(error.message || 'Lỗi');
        }
      })
    },
    sortingChanged(ctx) {
      this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
      this.currentSort = ctx.sortBy;
      this.fetchData();
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    fetchBrand() {
      this.listBrand = [];
      let param = {
        page: 1,
        pageSize: 1000,
        name: '',
      };
      let paramQuery = {
        params: {
          param,
        },
      };
      ApiService.setHeader();
      ApiService.query('brands', paramQuery).then((response) => {
        response.data.brands.forEach((item) => {
          let brand = {
            id: item.id,
            name: item.name,
          };
          this.brandOptions[0].data.push(brand);
        });
        this.filteredBrandOptions = [...this.brandOptions[0].data];
      });
    },
    onSelected(option) {
      this.searchStock = option.item.name;
      this.selectedStore = option.item.id;
      this.fetchData();
    },
    getSuggestionValue(suggestion) {
      return suggestion.item.name;
    },
    getValuesMultiSelect(arr, prop) {
      return arr.reduce((accumulator, currentValue) => {
        if (currentValue.checked) accumulator.push(currentValue[prop]);
        return accumulator;
      }, []);
    },
    getParamFilters() {
      const storeIds = this.value.map(({ code }) => code);

      return {
        storeIds: storeIds,
        searchProduct: this.searchProduct ? this.searchProduct.trim() : null,
        cateId: this.selectedCateId || null,
        internalCateId: this.selectedCate2Id || null,
        brandId: this.selectedBrandId || null,
        productType: this.selectedProductType || null,
        haveStock: this.haveStock || null,
      };
    },
    exportExcel: function () {
      this.excelParams = this.getParamFilters();
      this.$bvModal.show('product-stock-excel');
    },
    debounceInput: decounce(function () {
      this.fetchData();
    }, 2000),
    checkViewOriginalPrice() {
      if (!this.checkPermission('PRODUCT_ORIGINAL_PRICE_VIEW')) {
        let listNew = this.fields.filter(
          (item) => item.key !== 'originalPrice',
        );
        this.fields = [];
        listNew.forEach((element) => {
          this.fields.push(element);
        });
        for (let index = 0; index < this.fields.length; index++) {
          const element = this.fields[index];
          if (element.key === 'productName') {
            this.fields[index].tdClass = 'productNameNotPriceClass';
            this.fields[index].thClass = 'productNameNotPriceClass';
            break;
          }
        }
      }
    },
    fetchStoreForOption: async function () {
      ApiService.setHeader();
      ApiService.get(`/stores/getStores`).then((response) => {
        const stores = response.data.data;
        stores.map((element) => {
          let option = {
            code: element.id,
            name: element.shortName,
            checked: false,
          };
          this.optionStores.push(option);
        });
        this.filterdOptions = cloneDeep(this.optionStores);
      });
    },
    fetchStore: async function () {
      this.fields = [
        {
          key: 'productName',
          label: 'Sản phẩm',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '250px',
          },
        },
        {
          key: 'sellingPrice',
          label: 'Giá bán',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '160px',
          },
        },
        {
          key: 'totalQuantityInStock',
          label: 'Tổng tồn',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '100px',
          },
        },
      ];
      let listId = [];
      if (this.value.length) {
        listId = this.value.map(({ code }) => code);
      }
      this.filterdOptions = cloneDeep(this.optionStores);
      let param = {
        listId: listId,
      };
      let paramQuery = {
        params: param,
      };

      ApiService.query(`/stores/getStores`, paramQuery).then((response) => {
        const stores = response.data.data;
        stores.map((element) => {
          this.optionsStore[0].data.push(element);
          let field = {
            key: element.id + '',
            label: element.shortName,
            sortable: false,
            thStyle: {
              textAlign: 'center',
              color: 'rgb(24, 28, 50)',
              width: '100px',
            },
            tdClass: 'text-right',
            formatter: (value) => {
              let strBase = `<b class='text-dark' title='Tồn có thể bán'>${value.quantityInStock}</b>`;

              if (value.transferInStock) {
                const haveShow = this.settingTable.quantity.includes(2);
                strBase += `<b class='prop-transfer text-primary font-size-setting ${
                  !haveShow ? 'd-none' : ''
                }' title='Đang chuyển kho'>
                     +${value.transferInStock}
                      </b>`;
              }

              if (value.holdingInStock) {
                const haveShow = this.settingTable.quantity.includes(3);
                strBase += `<b class='prop-holding text-warning font-size-setting ${
                  !haveShow ? 'd-none' : ''
                }' title='Đang giữ hàng'>
                      +${value.holdingInStock}
                      </b>`;
              }

              if (value.deliveryInStock) {
                const haveShow = this.settingTable.quantity.includes(4);
                strBase += `<b class='prop-holding text-danger font-size-setting ${
                  !haveShow ? 'd-none' : ''
                }' title='Đang vận chuyển'>
                      +${value.deliveryInStock}
                      </b>`;
              }
              if (value.warrantyInStock) {
                const haveShow = this.settingTable.quantity.includes(5);
                strBase += `<b class='prop-holding warranty-color font-size-setting ${
                  !haveShow ? 'd-none' : ''
                }' title='Đang bảo hành'>
                      +${value.warrantyInStock}
                      </b>`;
              }

              return strBase;
            },
          };
          // if (this.fields.length < 12) {
          this.fields.push(field);
          // }
        });
      });
    },
    onSelect(option) {
      let index = this.optionStores.findIndex(
        (item) => item.name == option.name,
      );
      this.optionStores[index].checked = true;
      this.filterdOptions = cloneDeep(this.optionStores);
    },

    onRemove(option) {
      let index = this.optionStores.findIndex(
        (item) => item.name == option.name,
      );
      this.optionStores[index].checked = false;
      this.filterdOptions = cloneDeep(this.optionStores);
    },

    onInputed(textInput = '') {
      this.searchStore(textInput);
    },
    searchStore(textInput) {
      let options = cloneDeep(this.optionStores);
      if (!textInput || !textInput.trim().length) {
        this.filterdOptions = map(options, (obj) => {
          return assign(obj, find(this.filterdOptions, { id: obj.id }));
        });
        return;
      }

      const indexChooseAll = options.findIndex((prop) => prop.id === -1);

      if (indexChooseAll > -1) {
        options.splice(indexChooseAll, 1);
      }

      options = map(options, (obj) => {
        return assign(obj, find(this.filterdOptions, { id: obj.id }));
      });

      this.filterdOptions = this.fitlerOptionsBy(
        options,
        textInput,
        'name',
        10,
      );
    },
    fitlerOptionsBy(items, textInput, prop, limit) {
      return cloneDeep(items)
        .filter((item) => {
          if (item) {
            const nameWTUnicode = removeAccents(item[prop] || '');
            const nameInputWTUnicode = removeAccents(textInput);
            const index = nameWTUnicode
              .toLowerCase()
              .indexOf(nameInputWTUnicode.toLowerCase());

            if (index > -1) {
              return true;
            }
          }
          return false;
        })
        .slice(0, limit);
    },
    customLabel(option) {
      return `${option.name}`;
    },
    onFilter() {
      this.$route.query.page = 1;
      this.$router.push({
        name: 'list-product-stock',
      });
      this.fetchStore();
      this.fetchData();
    },
    fetchCategory: function () {
      let param = {
        page: 1,
        limit: 1000,
        name: '',
        code: '',
      };
      let paramQuery = {
        params: param,
      };
      ApiService.setHeader();
      ApiService.query('category', paramQuery).then(({ data }) => {
        this.cateOptions[0].data = [];
        data.data.list_caterogy.forEach((element) => {
          let name = '';
          if (element.level === 1) {
            name = '-' + element.name;
          } else if (element.level === 2) {
            name = '--' + element.name;
          } else if (element.level === 3) {
            name = '---' + element.name;
          } else if (element.level === 4) {
            name = '----' + element.name;
          } else if (element.level === 5) {
            name = '-----' + element.name;
          } else if (element.level === 6) {
            name = '------' + element.name;
          } else {
            name = element.name;
          }
          let item = {
            id: element.id,
            suggestionName: name,
            name: element.name,
          };
          this.cateOptions[0].data.push(item);
        });

        this.filteredCateOptions = [...this.cateOptions[0].data];
      });
    },
    onSelectedCate(option) {
      this.selectedCateId = option.item.id;
      this.selectedCateName = option.item.name;
    },
    onInputCateChange(text) {
      this.selectedCateName = text;

      const filteredData = this.cateOptions[0].data.filter((item) => {
        return (
          removeAccents(item.name)
            .toLowerCase()
            .indexOf(removeAccents(text.toLowerCase())) > -1
        );
      });

      this.filteredCateOptions = [...filteredData];
    },
    onSelectedBrand(option) {
      this.selectedBrandId = option.item.id;
      this.selectedBrandName = option.item.name;
    },
    onInputBrandChange(text) {
      this.selectedBrandName = text;

      const filteredData = this.brandOptions[0].data.filter((item) => {
        return (
          removeAccents(item.name)
            .toLowerCase()
            .indexOf(removeAccents(text.toLowerCase())) > -1
        );
      });

      this.filteredBrandOptions = [...filteredData];
    },
    onSelectedCate2(option) {
      this.selectedCate2Id = option.item.id;
      this.selectedCate2Name = option.item.name;
    },
    onInputCate2Change(text) {
      this.selectedCate2Name = text;

      const filteredData = this.internalCateOptions[0].data.filter((item) => {
        return (
          removeAccents(item.name)
            .toLowerCase()
            .indexOf(removeAccents(text.toLowerCase())) > -1
        );
      });

      this.filteredInternalCateOptions = [...filteredData];
    },
    fetchInternalCategory: function () {
      let param = {
        page: 1,
        limit: 1000,
        name: '',
        code: '',
      };
      let paramQuery = {
        params: param,
      };
      ApiService.setHeader();
      ApiService.query('productExternalCategory/get-all', paramQuery).then(
        ({ data }) => {
          this.internalCateOptions[0].data = [];
          data.data.forEach((element) => {
            let name = '';
            if (element.level === 1) {
              name = '- ' + element.name;
            } else if (element.level === 2) {
              name = '- - ' + element.name;
            } else if (element.level === 3) {
              name = '- - - ' + element.name;
            } else if (element.level === 4) {
              name = '- - - - ' + element.name;
            } else if (element.level === 5) {
              name = '- - - - - ' + element.name;
            } else if (element.level === 6) {
              name = '- - - - - - ' + element.name;
            } else {
              name = element.name;
            }
            const item = {
              id: element.id,
              suggestionName: name,
              name: element.name,
            };
            this.internalCateOptions[0].data.push(item);
          });
          this.filteredInternalCateOptions = [
            ...this.internalCateOptions[0].data,
          ];
        },
      );
    },
  },
};
</script>
